// extracted by mini-css-extract-plugin
export var addRoleButton = "team-roles-and-responsibilities-form-module--addRoleButton--9c8ad";
export var bodyBase = "team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodyLarge = "team-roles-and-responsibilities-form-module--body-large--cc6a9 team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodyLargeBold = "team-roles-and-responsibilities-form-module--body-large-bold--bf250 team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodyRegular = "team-roles-and-responsibilities-form-module--body-regular--8271d team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodyRegularBold = "team-roles-and-responsibilities-form-module--body-regular-bold--b299c team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodySmall = "team-roles-and-responsibilities-form-module--body-small--64a18 team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var bodySmallBold = "team-roles-and-responsibilities-form-module--body-small-bold--93c4a team-roles-and-responsibilities-form-module--body-base--a2048 team-roles-and-responsibilities-form-module--site-font--bca75";
export var borderTop = "team-roles-and-responsibilities-form-module--border-top--acb52";
export var breakWordContainer = "team-roles-and-responsibilities-form-module--break-word-container--d0a46";
export var buttonIconBase = "team-roles-and-responsibilities-form-module--button-icon-base--9f286";
export var clickLink = "team-roles-and-responsibilities-form-module--click-link--3cfbd";
export var dropdownBase = "team-roles-and-responsibilities-form-module--dropdown-base--94313";
export var dropdownSelectBase = "team-roles-and-responsibilities-form-module--dropdown-select-base--d7851 team-roles-and-responsibilities-form-module--text-input--a9fe0";
export var flexCol = "team-roles-and-responsibilities-form-module--flex-col--81db2";
export var formErrorMessage = "team-roles-and-responsibilities-form-module--form-error-message--3d5e1";
export var h3 = "team-roles-and-responsibilities-form-module--h3--75475";
export var h4 = "team-roles-and-responsibilities-form-module--h4--62620";
export var hoverLink = "team-roles-and-responsibilities-form-module--hover-link--f2dd5";
export var hoverRow = "team-roles-and-responsibilities-form-module--hover-row--acd52";
export var membershipContainer = "team-roles-and-responsibilities-form-module--membership-container--ab196 team-roles-and-responsibilities-form-module--flex-col--81db2 team-roles-and-responsibilities-form-module--primary-border--e3038";
export var membershipHeader = "team-roles-and-responsibilities-form-module--membership-header--323dc";
export var membershipHeading = "team-roles-and-responsibilities-form-module--membership-heading--9c3af";
export var membershipLabel = "team-roles-and-responsibilities-form-module--membership-label--2dbcc";
export var moreFiltersBorderClass = "team-roles-and-responsibilities-form-module--more-filters-border-class--07737";
export var pageBg = "team-roles-and-responsibilities-form-module--page-bg--fe556";
export var pointer = "team-roles-and-responsibilities-form-module--pointer--fb525";
export var primaryBorder = "team-roles-and-responsibilities-form-module--primary-border--e3038";
export var shadowBoxLight = "team-roles-and-responsibilities-form-module--shadow-box-light--6950b";
export var siteFont = "team-roles-and-responsibilities-form-module--site-font--bca75";
export var slideDownAndFade = "team-roles-and-responsibilities-form-module--slideDownAndFade--3655f";
export var slideLeftAndFade = "team-roles-and-responsibilities-form-module--slideLeftAndFade--274d3";
export var slideRightAndFade = "team-roles-and-responsibilities-form-module--slideRightAndFade--f0f49";
export var slideUpAndFade = "team-roles-and-responsibilities-form-module--slideUpAndFade--b3fbb";
export var statusDecoration = "team-roles-and-responsibilities-form-module--status-decoration--42a41";
export var textInput = "team-roles-and-responsibilities-form-module--text-input--a9fe0";
export var textInverted = "team-roles-and-responsibilities-form-module--text-inverted--dd94a";
export var textMediumDark = "team-roles-and-responsibilities-form-module--text-medium-dark--6aa68";
export var tooltipFont = "team-roles-and-responsibilities-form-module--tooltipFont--95fec";
export var unstyledButton = "team-roles-and-responsibilities-form-module--unstyled-button--55300";