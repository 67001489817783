import React from 'react';

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import FormInputLabel from 'src/components/form-info-label/form-info-label';
import { allAdminRights } from 'src/utils/typedefs/role';
import { AddTeamMemberState } from 'src/utils/typedefs/team';

interface TeamAdminRightsInfoProps {
  values: AddTeamMemberState;
}

const TeamAdminRightsInfo: React.FC<TeamAdminRightsInfoProps> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      {allAdminRights
        .filter((adminRight) => values.adminRights[adminRight.name])
        .map((adminRight) => (
          <Grid key={adminRight.id} item xs={4}>
            <FormInputLabel className="" label={t('name')}>
              {t(adminRight.name)}
            </FormInputLabel>
          </Grid>
        ))}
    </Grid>
  );
};

export default TeamAdminRightsInfo;
