// extracted by mini-css-extract-plugin
export var adornmentIcon = "team-contact-details-module--adornment-icon--84a76";
export var bodyBase = "team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodyLarge = "team-contact-details-module--body-large--c138f team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodyLargeBold = "team-contact-details-module--body-large-bold--36b88 team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodyRegular = "team-contact-details-module--body-regular--248c9 team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodyRegularBold = "team-contact-details-module--body-regular-bold--3a482 team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodySmall = "team-contact-details-module--body-small--e0567 team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var bodySmallBold = "team-contact-details-module--body-small-bold--2c7bb team-contact-details-module--body-base--9a749 team-contact-details-module--site-font--ac0f0";
export var borderTop = "team-contact-details-module--border-top--23232";
export var breakWordContainer = "team-contact-details-module--break-word-container--d416f";
export var buttonIconBase = "team-contact-details-module--button-icon-base--a4b15";
export var clickLink = "team-contact-details-module--click-link--e3170";
export var dropdownBase = "team-contact-details-module--dropdown-base--ac186";
export var dropdownSelectBase = "team-contact-details-module--dropdown-select-base--f7d6c team-contact-details-module--text-input--965f0";
export var flexCol = "team-contact-details-module--flex-col--130e0";
export var formErrorMessage = "team-contact-details-module--form-error-message--4626e";
export var h3 = "team-contact-details-module--h3--7cf99";
export var h4 = "team-contact-details-module--h4--7e972";
export var hoverLink = "team-contact-details-module--hover-link--9d52e";
export var hoverRow = "team-contact-details-module--hover-row--97c95";
export var membershipContainer = "team-contact-details-module--membership-container--cbf2f team-contact-details-module--flex-col--130e0 team-contact-details-module--primary-border--0faca";
export var membershipHeader = "team-contact-details-module--membership-header--2a0cd";
export var membershipHeading = "team-contact-details-module--membership-heading--4c032";
export var membershipLabel = "team-contact-details-module--membership-label--e3ca8";
export var moreFiltersBorderClass = "team-contact-details-module--more-filters-border-class--36945";
export var pageBg = "team-contact-details-module--page-bg--9731f";
export var pointer = "team-contact-details-module--pointer--0c107";
export var primaryBorder = "team-contact-details-module--primary-border--0faca";
export var shadowBoxLight = "team-contact-details-module--shadow-box-light--bc697";
export var siteFont = "team-contact-details-module--site-font--ac0f0";
export var slideDownAndFade = "team-contact-details-module--slideDownAndFade--7ed87";
export var slideLeftAndFade = "team-contact-details-module--slideLeftAndFade--55e43";
export var slideRightAndFade = "team-contact-details-module--slideRightAndFade--7830f";
export var slideUpAndFade = "team-contact-details-module--slideUpAndFade--57559";
export var statusDecoration = "team-contact-details-module--status-decoration--d9e11";
export var textInput = "team-contact-details-module--text-input--965f0";
export var textInverted = "team-contact-details-module--text-inverted--86fe6";
export var textMediumDark = "team-contact-details-module--text-medium-dark--41c9d";
export var tooltipFont = "team-contact-details-module--tooltipFont--906a2";
export var unstyledButton = "team-contact-details-module--unstyled-button--9a2cd";