import React from 'react';

import { FastField, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { FaCalendar } from 'react-icons/fa';
import Adornment from 'src/components/adornment/adornment';
import DatePicker from 'src/components/date-picker/date-picker';
import FormContainer from 'src/components/form-container/form-container';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import FormNextStepButton from 'src/components/form-next-step-button/form-next-step-button';
import IconAdornment from 'src/components/icon-adornment/icon-adornment';
import InputLabel from 'src/components/input-label/input-label';
import Input from 'src/components/input/input';
import RadioButtonGroup from 'src/components/radio-button-group/radio-button-group';
import * as styles from 'src/components/team-contact-details-form/team-contact-details.module.less';
import TeamContactDetailsInfo from 'src/components/team-contact-details-info/team-contact-details-info';
import {
  AddTeamMemberActiveSteps,
  AddTeamMemberState,
  AddTeamMemberVisitedSteps,
  GenderBasic,
  HowToAddContact,
} from 'src/utils/typedefs/team';

interface TeamContactDetailsFormProps {
  formikProps: FormikProps<AddTeamMemberState>;
  step: AddTeamMemberActiveSteps;
  visitedSteps: AddTeamMemberVisitedSteps;
  setStep: (step: AddTeamMemberActiveSteps, visitedSteps: AddTeamMemberVisitedSteps) => void;
}

const TeamContactDetailsForm: React.FC<TeamContactDetailsFormProps> = ({
  formikProps,
  step,
  visitedSteps,
  setStep,
}) => {
  const { t } = useTranslation();

  const {
    values,
    setFieldValue,
    touched: { contactDetails: touched },
    errors: { contactDetails: errors },
  } = formikProps;

  const HOW_TO_ADD_CONTACT = [
    { label: t('lookup existing contacts'), value: HowToAddContact.LookUpExistingContacts },
    { label: t('add a new contact'), value: HowToAddContact.AddNewContact },
  ];

  const GENDER = [
    { label: t('female'), value: GenderBasic.Female },
    { label: t('male'), value: GenderBasic.Male },
  ];

  const handleSetNextStep = () => {
    setStep(AddTeamMemberActiveSteps.RolesAndResponsibilities, {
      ...visitedSteps,
      rolesAndResponsibilities: true,
    });
  };

  const handleSetThisStep = () => {
    setStep(AddTeamMemberActiveSteps.ContactDetails, {
      ...visitedSteps,
      contactDetails: true,
    });
  };

  const handleChangeDateOfBirth = (date: Date) => {
    setFieldValue('contactDetails.contact.dateOfBirth', date);
  };

  const findExistingContactForm = () => {
    return (
      <>
        {/* TODO: extract the membershipLabel to the separate styled component*/}
        <InputLabel className={styles.membershipLabel}>{t('contact')}</InputLabel>
        {/* TODO: implement search with autocomplete component*/}
        {/* TODO: after that - the contact with name and email will be set and it
            can be used in the TeamContactDetailsInfo
          */}
        <FastField name="contactDetails.contact.firstName" component={Input} outlined={true} disableUnderline={true} />
        {errors?.contact?.firstName && touched?.contact?.firstName && (
          <FormErrorMessage message={errors.contact?.firstName} />
        )}
        {/* End TODO */}
      </>
    );
  };

  const addNewContactForm = () => {
    return (
      <>
        <InputLabel className={styles.membershipLabel}>{t('first name')}*</InputLabel>
        <FastField name="contactDetails.contact.firstName" component={Input} outlined={true} disableUnderline={true} />
        {errors?.contact?.firstName && touched?.contact?.firstName && (
          <FormErrorMessage message={errors.contact?.firstName} />
        )}
        <InputLabel className={styles.membershipLabel}>{t('last name')}*</InputLabel>
        <FastField name="contactDetails.contact.lastName" component={Input} outlined={true} disableUnderline={true} />
        {errors?.contact?.lastName && touched?.contact?.lastName && (
          <FormErrorMessage message={errors.contact?.lastName} />
        )}
        <InputLabel className={styles.membershipLabel}>{t('email')}*</InputLabel>
        <FastField name="contactDetails.contact.email" component={Input} outlined={true} disableUnderline={true} />
        {errors?.contact?.email && touched?.contact?.email && <FormErrorMessage message={errors.contact?.email} />}
        <InputLabel className={styles.membershipLabel}>{t('gender')}*</InputLabel>
        <FastField
          name="contactDetails.contact.gender"
          options={GENDER}
          setFieldValue={setFieldValue}
          component={RadioButtonGroup}
          isRow={true}
        />
        <InputLabel className={styles.membershipLabel}>{t('date of birth')}*</InputLabel>
        <IconAdornment>
          <Adornment>
            <FaCalendar className={styles.adornmentIcon} />
          </Adornment>
          <FastField
            name="contactDetails.contact.dateOfBirth"
            setFieldValue={setFieldValue}
            onChange={handleChangeDateOfBirth}
            component={DatePicker}
          />
        </IconAdornment>
        {errors?.contact?.dateOfBirth && touched?.contact?.dateOfBirth && (
          <FormErrorMessage message={errors.contact?.dateOfBirth} />
        )}
        <InputLabel className={styles.membershipLabel}>{t('phone')}*</InputLabel>
        <FastField
          name="contactDetails.contact.phone"
          size="md"
          component={Input}
          outlined={true}
          disableUnderline={true}
        />
        {errors?.contact?.phone && touched?.contact?.phone && <FormErrorMessage message={errors.contact?.phone} />}
      </>
    );
  };

  return (
    <FormContainer
      headingText={t('contact details')}
      displayEditButton={step !== AddTeamMemberActiveSteps.ContactDetails && visitedSteps.contactDetails}
      setThisStep={handleSetThisStep}
    >
      {step === AddTeamMemberActiveSteps.ContactDetails ? (
        <>
          <InputLabel className={styles.membershipLabel}>{t('add contact method')}</InputLabel>
          <FastField
            name="contactDetails.howToAddContact"
            options={HOW_TO_ADD_CONTACT}
            setFieldValue={setFieldValue}
            component={RadioButtonGroup}
          />
          {values.contactDetails.howToAddContact === HowToAddContact.LookUpExistingContacts
            ? findExistingContactForm()
            : addNewContactForm()}
          <FormNextStepButton
            disabled={!!errors || !touched}
            buttonText={t('continue')}
            handleSetNextStep={handleSetNextStep}
          />
        </>
      ) : (
        visitedSteps.contactDetails && <TeamContactDetailsInfo values={values} />
      )}
    </FormContainer>
  );
};

export default TeamContactDetailsForm;
