// extracted by mini-css-extract-plugin
export var bodyBase = "team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodyLarge = "team-admin-rights-form-module--body-large--063dd team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodyLargeBold = "team-admin-rights-form-module--body-large-bold--052bf team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodyRegular = "team-admin-rights-form-module--body-regular--e1a77 team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodyRegularBold = "team-admin-rights-form-module--body-regular-bold--31a64 team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodySmall = "team-admin-rights-form-module--body-small--9696e team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var bodySmallBold = "team-admin-rights-form-module--body-small-bold--57e61 team-admin-rights-form-module--body-base--441ab team-admin-rights-form-module--site-font--12578";
export var borderTop = "team-admin-rights-form-module--border-top--5b4f7";
export var breakWordContainer = "team-admin-rights-form-module--break-word-container--69870";
export var buttonIconBase = "team-admin-rights-form-module--button-icon-base--8dadd";
export var clickLink = "team-admin-rights-form-module--click-link--4fe43";
export var dropdownBase = "team-admin-rights-form-module--dropdown-base--5f324";
export var dropdownSelectBase = "team-admin-rights-form-module--dropdown-select-base--8bd44 team-admin-rights-form-module--text-input--2a398";
export var flexCol = "team-admin-rights-form-module--flex-col--4b718";
export var formErrorMessage = "team-admin-rights-form-module--form-error-message--2289c";
export var h3 = "team-admin-rights-form-module--h3--0f8ed";
export var h4 = "team-admin-rights-form-module--h4--82f97";
export var hoverLink = "team-admin-rights-form-module--hover-link--43407";
export var hoverRow = "team-admin-rights-form-module--hover-row--b2258";
export var membershipContainer = "team-admin-rights-form-module--membership-container--bf275 team-admin-rights-form-module--flex-col--4b718 team-admin-rights-form-module--primary-border--145f3";
export var membershipHeader = "team-admin-rights-form-module--membership-header--5bdd4";
export var membershipHeading = "team-admin-rights-form-module--membership-heading--d55b4";
export var membershipLabel = "team-admin-rights-form-module--membership-label--fe434";
export var moreFiltersBorderClass = "team-admin-rights-form-module--more-filters-border-class--67cbb";
export var pageBg = "team-admin-rights-form-module--page-bg--cb711";
export var pointer = "team-admin-rights-form-module--pointer--24408";
export var primaryBorder = "team-admin-rights-form-module--primary-border--145f3";
export var shadowBoxLight = "team-admin-rights-form-module--shadow-box-light--9df49";
export var siteFont = "team-admin-rights-form-module--site-font--12578";
export var slideDownAndFade = "team-admin-rights-form-module--slideDownAndFade--1efa3";
export var slideLeftAndFade = "team-admin-rights-form-module--slideLeftAndFade--c958f";
export var slideRightAndFade = "team-admin-rights-form-module--slideRightAndFade--e1ebe";
export var slideUpAndFade = "team-admin-rights-form-module--slideUpAndFade--0ae83";
export var statusDecoration = "team-admin-rights-form-module--status-decoration--61d4d";
export var textInput = "team-admin-rights-form-module--text-input--2a398";
export var textInverted = "team-admin-rights-form-module--text-inverted--de099";
export var textMediumDark = "team-admin-rights-form-module--text-medium-dark--b98a2";
export var tooltipFont = "team-admin-rights-form-module--tooltipFont--5bad6";
export var unstyledButton = "team-admin-rights-form-module--unstyled-button--072d7";