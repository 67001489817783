import React from 'react';

import Button from 'src/components/button/button';
import * as styles from 'src/components/form-next-step-button/form-next-step-button.module.less';

interface FormNextStepButtonProps {
  buttonText: string;
  disabled?: boolean;
  handleSetNextStep: () => void;
}

const FormNextStepButton: React.FC<FormNextStepButtonProps> = ({ buttonText, disabled, handleSetNextStep }) => {
  return (
    <div className={styles.buttonMargin}>
      <Button disabled={disabled} size="md" type="button" onClick={handleSetNextStep}>
        {buttonText}
      </Button>
    </div>
  );
};

export default FormNextStepButton;
